import React from "react";

import * as Styled from "../Styled";
import { DynamicSidebarItem } from "../types";

type Props = React.PropsWithChildren<{
  dynamicSidebarItem: DynamicSidebarItem;
}>;

export const DynamicSidebarNonReorderItem: React.FC<Props> = ({
  dynamicSidebarItem,
  children,
}) => {
  const El =
    dynamicSidebarItem.type === "area"
      ? Styled.DynamicSidebarNonReorderItemLeader
      : Styled.DynamicSidebarNonReorderItem;

  return <El>{children}</El>;
};
