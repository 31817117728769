import { SectionId } from "./types";

export const INITIAL_SIDEBAR_HANDLE_X = 300;
export const SIDEBAR_HANDLE_X_SPACING = 8;
export const SIDEBAR_HANDLE_WIDTH = 8;
export const SIDEBAR_HANDLE_HEIGHT = 40;

export const MIN_SIDEBAR_WIDTH = 250;

export const SIDEBAR_ANIMATION_DURATION = 200;

export const TOTAL_SIDEBAR_HANDLE_WIDTH_FROM_LEFT =
  INITIAL_SIDEBAR_HANDLE_X +
  SIDEBAR_HANDLE_WIDTH +
  SIDEBAR_HANDLE_X_SPACING * 2;

export const IS_TOUCH_SCREEN =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  (navigator as any).msMaxTouchPoints > 0;

export const sectionNamesById: Record<SectionId, string> = {
  anytime: "Anytime",
  inbox: "Inbox",
  logbook: "Logbook",
  someday: "Someday",
  today: "Today",
  trash: "Trash",
  upcoming: "Upcoming",
};
