import React from "react";

import * as constants from "../constants";
import * as Styled from "../Styled";
import { useAppStore } from "../store";

export const SidebarOpener: React.FC = () => {
  const isSidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSidebarAnimating = useAppStore((state) => state.isSidebarAnimating);
  const openSidebar = useAppStore((state) => state.openSidebar);

  const [isMouseOverSidebarOpenerHandle, setIsMouseOverSidebarOpenerHandle] =
    React.useState(false);
  const [
    isMouseOverSidebarOpenerAppearZone,
    setIsMouseOverSidebarOpenerAppearZone,
  ] = React.useState(false);

  const onClick = () => {
    setIsMouseOverSidebarOpenerAppearZone(false);
    setIsMouseOverSidebarOpenerHandle(false);
    openSidebar();
  };

  let shouldShowHandle = false;
  if (constants.IS_TOUCH_SCREEN) {
    shouldShowHandle = !isSidebarAnimating;
  } else {
    shouldShowHandle =
      isMouseOverSidebarOpenerAppearZone || isMouseOverSidebarOpenerHandle;
  }
  const opacityForHandle = shouldShowHandle ? 1 : 0;

  if (isSidebarOpen) {
    return null;
  }
  return (
    <>
      <Styled.SidebarOpenerAppearZone
        onMouseOver={() => setIsMouseOverSidebarOpenerAppearZone(true)}
        onMouseOut={() => setIsMouseOverSidebarOpenerAppearZone(false)}
      />
      <Styled.SidebarOpenerHandle
        onMouseOver={() => setIsMouseOverSidebarOpenerHandle(true)}
        onMouseOut={() => setIsMouseOverSidebarOpenerHandle(false)}
        onClick={onClick}
        style={{ opacity: opacityForHandle }}
      />
    </>
  );
};
