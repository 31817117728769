import React from "react";

import * as Styled from "./Styled";
import { MainContentHeader } from "./MainContentHeader";

export const MainContent: React.FC = () => {
  return (
    <Styled.MainContent>
      <MainContentHeader />
    </Styled.MainContent>
  );
};
