import { StateCreator } from "../state";

import { SidebarNewListPopover } from "./sidebarNewListPopoverSlice/types";

export const createSidebarNewListPopover: StateCreator<
  SidebarNewListPopover
> = (set) => ({
  isShowingNewListPopover: false,
  showNewListPopover: () => set(() => ({ isShowingNewListPopover: true })),
  hideNewListPopover: () => set(() => ({ isShowingNewListPopover: false })),
});
