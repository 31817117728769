import { Area } from "../../../types";

export const defaultAreasById: Record<string, Area> = {
  DEFAULT_AREA_FAMILY: {
    id: "DEFAULT_AREA_FAMILY",
    name: "Family",
    projectIds: ["DEFAULT_PROJECT_VACATION", "DEFAULT_PROJECT_PURCHASE"],
  },
  DEFAULT_AREA_WORK: {
    id: "DEFAULT_AREA_WORK",
    name: "Work",
    projectIds: ["DEFAULT_PROJECT_PREPARE", "DEFAULT_PROJECT_ATTEND"],
  },
  DEFAULT_AREA_HOBBIES: {
    id: "DEFAULT_AREA_HOBBIES",
    name: "Hobbies",
    projectIds: ["DEFAULT_PROJECT_LEARN", "DEFAULT_PROJECT_RUN"],
  },
};

export const defaultAreaIds: string[] = Object.keys(defaultAreasById);
