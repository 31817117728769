import { StateCreator } from "../state";

import { ContextMenuSlice } from "./contextMenuSlice/types";

export const createContextMenuSlice: StateCreator<ContextMenuSlice> = (
  set
) => ({
  zoneForContextMenu: "sidebar",
  elForContextMenu: null,
  itemForContextMenu: null,
  openContextMenu: ({
    elForContextMenu,
    itemForContextMenu,
    zoneForContextMenu,
  }) => {
    set(() => ({ elForContextMenu, itemForContextMenu, zoneForContextMenu }));
  },
  closeContextMenu: () => {
    set(() => ({ elForContextMenu: null, itemForContextMenu: null }));
  },
});
