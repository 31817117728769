import { StateCreator } from "../state";

import { RenamingSlice } from "./renamingSlice/types";

export const createRenamingSlice: StateCreator<RenamingSlice> = (set) => ({
  zoneForItemBeingRenamed: "sidebar",
  itemBeingRenamed: null,
  setItemBeingRenamed: ({ item, zone }) => {
    set(({ zoneForItemBeingRenamed }) => ({
      itemBeingRenamed: item,
      zoneForItemBeingRenamed: zone ?? zoneForItemBeingRenamed,
    }));
  },
});
