import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faICursor } from "@fortawesome/free-solid-svg-icons";

import { HoverableIconComponent } from "./types";

export const RenameIcon: HoverableIconComponent = (props) => {
  const { isHovered, ...restOfProps } = props;
  return (
    <FontAwesomeIcon
      {...restOfProps}
      icon={faICursor}
      color={isHovered ? "#FFFFFF" : "#639AF8"}
    />
  );
};
