import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/free-solid-svg-icons";

import { IconComponent } from "./types";

export const ProjectIcon: IconComponent = (props) => {
  return (
    <FontAwesomeIcon {...props} icon={faCircleHalfStroke} color="#77AAF8" />
  );
};
