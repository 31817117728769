import styled from "styled-components";

export const MainContent = styled.div`
  flex: 1 1 auto;
  max-width: 1020px;
  display: flex;
  column-gap: 16px;
  font-size: 24px;
`;

export const MainContentHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
`;

export const MainContentHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-size: 24px;
  line-height: 1.1;
`;

export const MainContentTitle = styled.div<{ isRenaming?: boolean }>`
  outline: none;
  border: none;
  display: flex;

  ${({ isRenaming }) => (isRenaming ? "flex: 1 1 auto;" : "")}
`;

export const MainContentRenameTitleInput = styled.input`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #25272b;
  background: unset;
  outline: unset;
  border: unset;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  margin-top: -5px;
  margin-bottom: -4px;
`;

export const MainContentNotes = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
