import React from "react";

import * as Styled from "../Styled";
import * as Icons from "../icons";
import { useAppStore } from "../store";

export const NewListPopup: React.FC = () => {
  const isShowingNewListPopover = useAppStore(
    (state) => state.isShowingNewListPopover
  );
  const addArea = useAppStore((state) => state.addArea);
  const addProject = useAppStore((state) => state.addProject);
  const hideNewListPopover = useAppStore((state) => state.hideNewListPopover);

  const onNewAreaClick = () => {
    addArea();
    hideNewListPopover();
  };

  const onNewProjectClick = () => {
    addProject();
    hideNewListPopover();
  };

  return (
    <Styled.NewListPopup hide={!isShowingNewListPopover}>
      <Styled.NewListPopupItem
        tabIndex={isShowingNewListPopover ? 0 : undefined}
        onClick={onNewProjectClick}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onNewProjectClick();
          }
        }}
      >
        <Styled.NewListPopupItemIcon>
          <Icons.ProjectIcon />
        </Styled.NewListPopupItemIcon>
        <Styled.NewListPopupItemTitle>New Project</Styled.NewListPopupItemTitle>
        <Styled.NewListPopupItemBlank />
        <Styled.NewListPopupItemDescription>
          Define a goal, then work towards it one to-do at a time.
        </Styled.NewListPopupItemDescription>
      </Styled.NewListPopupItem>
      <Styled.NewListPopupItemDivider />
      <Styled.NewListPopupItem
        tabIndex={isShowingNewListPopover ? 0 : undefined}
        onClick={onNewAreaClick}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onNewAreaClick();
          }
        }}
      >
        <Styled.NewListPopupItemIcon>
          <Icons.AreaIcon />
        </Styled.NewListPopupItemIcon>
        <Styled.NewListPopupItemTitle>New Area</Styled.NewListPopupItemTitle>
        <Styled.NewListPopupItemBlank />
        <Styled.NewListPopupItemDescription>
          Group your projects and to-dos based on different responsibilities,
          such as Family or Work.
        </Styled.NewListPopupItemDescription>
      </Styled.NewListPopupItem>
    </Styled.NewListPopup>
  );
};
