import * as constants from "../../constants";

import { StateCreator } from "../state";

import { SidebarReorderingSlice } from "./sidebarReorderingSlice/types";

export const createSidebarReorderingSlice: StateCreator<
  SidebarReorderingSlice
> = (set) => ({
  isInReorderingSidebarMode: !constants.IS_TOUCH_SCREEN,
  setIsInReorderingSidebarMode: (value) => {
    set(() => ({ isInReorderingSidebarMode: value }));
  },
  currentDraggingSidebarItem: null,
  setCurrentDraggingSidebarItem: (item) => {
    set(() => ({ currentDraggingSidebarItem: item }));
  },
  reorderDynamicSidebarItems: (items) => {
    set(({ areasById, projectsById, currentDraggingSidebarItem }) => {
      if (currentDraggingSidebarItem?.type === "area") {
        // When the currentDraggingSidebarItem is an area,
        // we only allow reordering areas.
        const nextAreaIds = [];
        for (const item of items) {
          if (item.type === "area") {
            nextAreaIds.push(item.id);
          }
        }
        return {
          areaIds: nextAreaIds,
        };
      } else {
        const nextProjectIdsWithoutArea = [];
        const nextAreaIds = [];
        const nextAreasById = { ...areasById };
        const nextProjectsById = { ...projectsById };
        for (const areaId in nextAreasById) {
          nextAreasById[areaId].projectIds = [];
        }

        let currentAreaId: string | undefined;
        for (const item of items) {
          if (item.type === "project") {
            if (!currentAreaId) {
              nextProjectIdsWithoutArea.push(item.id);
              nextProjectsById[item.id].areaId = null;
            } else {
              nextAreasById[currentAreaId].projectIds.push(item.id);
              nextProjectsById[item.id].areaId = currentAreaId;
            }
          } else {
            currentAreaId = item.id;
            nextAreaIds.push(item.id);
          }
        }

        return {
          projectIdsWithoutArea: nextProjectIdsWithoutArea,
          areaIds: nextAreaIds,
          areasById: nextAreasById,
          projectsById: nextProjectsById,
        };
      }
    });
  },
});
