import Div100vh from "react-div-100vh";
import styled from "styled-components";
import { motion, Reorder } from "framer-motion";

import * as constants from "./constants";

export const App = styled.div`
  position: relative;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2rem;
  color: #25272b;
  display: grid;
  grid-template-areas:
    "sidebar-body main-body"
    "sidebar-footer main-footer";
  grid-template-rows: 1fr 40px;
`.withComponent(Div100vh);

export const SidebarResizerAppearZone = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${constants.SIDEBAR_HANDLE_WIDTH +
  constants.SIDEBAR_HANDLE_X_SPACING * 2}px;
`;
export const SidebarResizerHandle = styled.div`
  position: absolute;
  left: ${constants.INITIAL_SIDEBAR_HANDLE_X +
  constants.SIDEBAR_HANDLE_X_SPACING}px;
  top: calc(
    50% - ${constants.SIDEBAR_HANDLE_HEIGHT / 2}px
  ); // vertical centering

  background-color: #e0e3e8;
  width: ${constants.SIDEBAR_HANDLE_WIDTH}px;
  height: ${constants.SIDEBAR_HANDLE_HEIGHT}px;
  border-radius: 4px;
  cursor: col-resize;

  transition: opacity 0.3s;

  &:active {
    background-color: #a9abb0;
  }
`.withComponent(motion.div);

export const SidebarOpenerAppearZone = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${constants.SIDEBAR_HANDLE_WIDTH +
  constants.SIDEBAR_HANDLE_X_SPACING * 2}px;
`;
export const SidebarOpenerHandle = styled.div`
  position: absolute;
  left: ${constants.SIDEBAR_HANDLE_X_SPACING}px;
  top: calc(
    50% - ${constants.SIDEBAR_HANDLE_HEIGHT / 2}px
  ); // vertical centering

  background-color: #e0e3e8;
  width: ${constants.SIDEBAR_HANDLE_WIDTH}px;
  height: ${constants.SIDEBAR_HANDLE_HEIGHT}px;
  border-radius: 4px;
  cursor: pointer;

  transition: opacity 0.3s;

  &:active {
    background-color: #a9abb0;
  }
`;

export const SidebarBody = styled.div`
  grid-area: sidebar-body;
  overflow-y: auto;
  background-color: HSL(210, 20%, 98%);
`;
export const SidebarBodyInner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
`.withComponent(motion.div);

export const SidebarFooter = styled.div`
  grid-area: sidebar-footer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e7e8ec;
  color: #5a5c60;
  font-size: 14px;
  background: linear-gradient(180deg, #f5f6f8 0%, #f6f7f9 100%);
`;
export const SidebarFooterInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`.withComponent(motion.div);

const SidebarFooterButton = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  outline: none;
  border: 1px solid HSLA(223, 12%, 88%, 0);
  ${({ isSelected }) =>
    isSelected ? "background-color: HSL(223, 12%, 88%);" : ""};

  &:hover,
  &:focus {
    border: 1px solid HSL(223, 12%, 88%);
  }

  &:active {
    background-color: HSL(223, 12%, 88%);
  }
`;
export const SidebarFooterLeft = styled.div`
  position: relative;
`;
export const SidebarFooterRight = styled.div`
  display: flex;
  column-gap: 5px;
`;
export const SidebarFooterRightButton = styled(SidebarFooterButton)``;
export const NewListButton = styled(SidebarFooterButton)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
`;

export const MainBody = styled.div`
  grid-area: main-body;
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;
export const MainFooter = styled.div`
  grid-area: main-footer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f1f1f3;
  overflow: hidden;
`;

export const StaticSidebarGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;
export const StaticSidebarItem = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  outline: none;
  ${({ isSelected }) =>
    isSelected ? "background-color: HSL(223, 12%, 88%)" : ""};

  &:hover,
  &:focus {
    ${({ isSelected }) =>
      !isSelected ? "background-color: HSL(223, 12%, 95%)" : ""};
  }
`;
export const StaticSidebarItemName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  overflow: hidden;
  white-space: nowrap;
`;
export const StaticSidebarItemCount = styled.div`
  color: #95979c;
`;
export const StaticSidebarItemNameText = styled.div`
  outline: none;
  border: none;
`;

export const DynamicSidebarNonReorderItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;
export const DynamicSidebarReorderItemGroup =
  DynamicSidebarNonReorderItemGroup.withComponent(Reorder.Group);

const _DynamicSidebarNonReorderItemLeader = styled.div`
  margin-top: 15px;
`;
export const DynamicSidebarReorderItemLeader =
  _DynamicSidebarNonReorderItemLeader.withComponent(Reorder.Item);
export const DynamicSidebarNonReorderItemLeader =
  _DynamicSidebarNonReorderItemLeader.withComponent(motion.div);

export const _DynamicSidebarNonReorderItem = styled.div``;
export const DynamicSidebarReorderItem =
  _DynamicSidebarNonReorderItem.withComponent(Reorder.Item);
export const DynamicSidebarNonReorderItem =
  _DynamicSidebarNonReorderItem.withComponent(motion.div);

const PopUpMenu = styled.div<{ hide: boolean }>`
  position: absolute;
  z-index: 101;
  background-color: #2d3238;
  border-radius: 8px;
  transition: transform 0.05s;

  ${({ hide }) =>
    hide
      ? "z-index: -1; opacity: 0; transform: scale(0.8);"
      : "opacity: 1; transform: scale(1);"}
`;

export const PopoverBackgroundOverlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const PopupContextMenu = styled.div`
  top: 0;
  transform-origin: center left;
`.withComponent(PopUpMenu);

export const NewListPopup = styled.div`
  bottom: 40px;
  left: -15px;
  width: 325px;
  transform-origin: bottom center;
`.withComponent(PopUpMenu);

export const PopupContextMenuItem = styled.div`
  display: grid;
  grid-template-areas: "icon title";
  grid-template-columns: 30px 1fr;
  grid-template-rows: 1fr;

  margin: 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #639af8;
  }
`;

export const EmptyPopupContextMenuItem = styled.div`
  display: flex;
  justify-content: center;
  color: #ffffff;
  opacity: 0.5;
  font-size: 0.75rem;

  &:hover,
  &:focus {
    background-color: unset;
  }
`.withComponent(PopupContextMenuItem);

export const NewListPopupItem = styled.div`
  display: grid;
  grid-template-areas:
    "icon title"
    "blank description";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
`.withComponent(PopupContextMenuItem);

export const PopupContextMenuItemIcon = styled.div`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 5px;
`;
export const NewListPopupItemIcon = styled.div``.withComponent(
  PopupContextMenuItemIcon
);
export const PopupContextMenuItemTitle = styled.div`
  padding-right: 15px;
  grid-area: title;
  color: #ffffff;
  display: flex;
  align-items: center;
`;
export const NewListPopupItemTitle = styled.div``.withComponent(
  PopupContextMenuItemTitle
);
export const PopupContextMenuItemDivider = styled.div`
  background-color: rgba(255, 255, 255, 0.45);
  height: 1px;
  margin: 0 10px;
`;
export const NewListPopupItemDivider = styled.div``.withComponent(
  PopupContextMenuItemDivider
);

export const NewListPopupItemBlank = styled.div`
  grid-area: blank;
`;
export const NewListPopupItemDescription = styled.div`
  grid-area: description;
  color: rgba(255, 255, 255, 0.45);
  padding-bottom: 5px;
`;

export const SidebarItemRenameInput = styled.input`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #25272b;
  background: unset;
  outline: unset;
  border: unset;
  margin: unset;
  padding: unset;
`;
