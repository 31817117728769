import React from "react";

import * as constants from "./constants";
import * as Styled from "./Styled";
import * as Icons from "./icons";
import { useAppStore } from "./store";
import { DynamicSidebarItem } from "./types";

import { DynamicSidebarNonReorderItem } from "./components/DynamicSidebarNonReorderItem";
import { DynamicSidebarReorderItem } from "./components/DynamicSidebarReorderItem";
import { PopupContextMenu } from "./components/PopupContextMenu";
import { SidebarFooterLeft } from "./components/SidebarFooterLeft";
import { SidebarOpener } from "./components/SidebarOpener";
import { SidebarResizer } from "./components/SidebarResizer";
import { StaticSidebarItem } from "./components/StaticSidebarItem";
import { MainContent } from "./mainContent/MainContent";
import { sectionNamesById } from "./constants";

export const App: React.FC = () => {
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const isSidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSidebarAnimating = useAppStore((state) => state.isSidebarAnimating);
  const isDraggingSidebar = useAppStore((state) => state.isDraggingSidebar);
  const sidebarHandleX = useAppStore((state) => state.sidebarHandleX);
  const isShowingNewListPopover = useAppStore(
    (state) => state.isShowingNewListPopover
  );
  const sectionsById = useAppStore((state) => state.sectionsById);
  const areaIds = useAppStore((state) => state.areaIds);
  const areasById = useAppStore((state) => state.areasById);
  const reorderDynamicSidebarItems = useAppStore(
    (state) => state.reorderDynamicSidebarItems
  );

  const projectsById = useAppStore((state) => state.projectsById);
  const projectIdsWithoutArea = useAppStore(
    (state) => state.projectIdsWithoutArea
  );

  const currentDraggingSidebarItem = useAppStore(
    (state) => state.currentDraggingSidebarItem
  );
  const setCurrentDraggingSidebarItem = useAppStore(
    (state) => state.setCurrentDraggingSidebarItem
  );

  const isInReorderingSidebarMode = useAppStore(
    (state) => state.isInReorderingSidebarMode
  );
  const setIsInReorderingSidebarMode = useAppStore(
    (state) => state.setIsInReorderingSidebarMode
  );

  const [
    showInitialDynamicSidebarReorderItemAnimation,
    setShowInitialDynamicSidebarReorderItemAnimation,
  ] = React.useState(false);

  const dynamicSidebarItems: DynamicSidebarItem[] = React.useMemo(() => {
    const items: DynamicSidebarItem[] = [];
    for (const projectId of projectIdsWithoutArea) {
      items.push({ type: "project", id: projectId });
    }
    for (const areaId of areaIds) {
      items.push({ type: "area", id: areaId });

      const area = areasById[areaId];
      for (const projectId of area.projectIds) {
        items.push({ type: "project", id: projectId });
      }
    }
    return items;
  }, [projectIdsWithoutArea, areaIds, areasById]);

  const dynamicSidebarElements = React.useMemo(() => {
    const elements: JSX.Element[] = [];
    for (const dynamicSidebarItem of dynamicSidebarItems) {
      if (
        currentDraggingSidebarItem?.type === "area" &&
        dynamicSidebarItem.type === "project"
      ) {
        elements.push(
          <Styled.DynamicSidebarNonReorderItem
            initial={{ opacity: 1 }}
            animate={{ opacity: 0.2 }}
            key={dynamicSidebarItem.id}
          >
            <StaticSidebarItem
              type={dynamicSidebarItem.type}
              Icon={Icons.ProjectIcon}
              title={projectsById[dynamicSidebarItem.id].name}
              id={dynamicSidebarItem.id}
            />
          </Styled.DynamicSidebarNonReorderItem>
        );
      } else {
        const staticSidebarItemEl = (
          <StaticSidebarItem
            type={dynamicSidebarItem.type}
            Icon={
              dynamicSidebarItem.type === "area"
                ? Icons.AreaIcon
                : Icons.ProjectIcon
            }
            title={
              dynamicSidebarItem.type === "area"
                ? areasById[dynamicSidebarItem.id].name
                : projectsById[dynamicSidebarItem.id].name
            }
            id={dynamicSidebarItem.id}
          />
        );
        if (isDraggingSidebar || !isInReorderingSidebarMode) {
          elements.push(
            <DynamicSidebarNonReorderItem
              key={dynamicSidebarItem.id}
              dynamicSidebarItem={dynamicSidebarItem}
            >
              {staticSidebarItemEl}
            </DynamicSidebarNonReorderItem>
          );
        } else {
          elements.push(
            <DynamicSidebarReorderItem
              key={dynamicSidebarItem.id}
              onDragStart={() => {
                setCurrentDraggingSidebarItem(dynamicSidebarItem);
              }}
              onDragEnd={() => {
                setCurrentDraggingSidebarItem(null);
              }}
              showInitialDynamicSidebarReorderItemAnimation={
                showInitialDynamicSidebarReorderItemAnimation
              }
              dynamicSidebarItem={dynamicSidebarItem}
            >
              {staticSidebarItemEl}
            </DynamicSidebarReorderItem>
          );
        }
      }
    }
    return elements;
  }, [
    showInitialDynamicSidebarReorderItemAnimation,
    isDraggingSidebar,
    dynamicSidebarItems,
    areasById,
    projectsById,
    currentDraggingSidebarItem,
    isInReorderingSidebarMode,
    setCurrentDraggingSidebarItem,
  ]);

  React.useEffect(() => {
    setIsFirstRender(false);
    setShowInitialDynamicSidebarReorderItemAnimation(true);
  }, []);

  React.useEffect(() => {
    if (isDraggingSidebar) {
      setShowInitialDynamicSidebarReorderItemAnimation(false);
    } else {
      setShowInitialDynamicSidebarReorderItemAnimation(true);
    }
  }, [isDraggingSidebar]);

  const sidebarWidth = isSidebarOpen ? sidebarHandleX : 0;

  return (
    <Styled.App
      style={{
        transition: isSidebarAnimating
          ? `all ${constants.SIDEBAR_ANIMATION_DURATION / 1000}s`
          : undefined,
        gridTemplateColumns: `${sidebarWidth}px 1fr`,
      }}
    >
      <PopupContextMenu />
      <SidebarOpener />
      <SidebarResizer />
      <Styled.SidebarBody>
        {isSidebarOpen && !isSidebarAnimating ? (
          <Styled.SidebarBodyInner
            initial={isFirstRender ? false : { opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Styled.StaticSidebarGroup>
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["inbox"]}
                type="section"
                title={sectionNamesById["inbox"]}
                id="inbox"
                count={sectionsById["inbox"].count}
              />
            </Styled.StaticSidebarGroup>
            <Styled.StaticSidebarGroup>
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["today"]}
                type="section"
                title={sectionNamesById["today"]}
                id="today"
              />
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["upcoming"]}
                type="section"
                title={sectionNamesById["upcoming"]}
                id="upcoming"
              />
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["anytime"]}
                type="section"
                title={sectionNamesById["anytime"]}
                id="anytime"
              />
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["someday"]}
                type="section"
                title={sectionNamesById["someday"]}
                id="someday"
              />
            </Styled.StaticSidebarGroup>
            <Styled.StaticSidebarGroup>
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["logbook"]}
                type="section"
                title={sectionNamesById["logbook"]}
                id="logbook"
              />
              <StaticSidebarItem
                Icon={Icons.iconsBySectionId["trash"]}
                type="section"
                title={sectionNamesById["trash"]}
                id="trash"
              />
            </Styled.StaticSidebarGroup>
            {isDraggingSidebar ? (
              <Styled.DynamicSidebarNonReorderItemGroup>
                {dynamicSidebarElements}
              </Styled.DynamicSidebarNonReorderItemGroup>
            ) : (
              <Styled.DynamicSidebarReorderItemGroup
                axis="y"
                values={dynamicSidebarItems}
                onReorder={reorderDynamicSidebarItems}
              >
                {dynamicSidebarElements}
              </Styled.DynamicSidebarReorderItemGroup>
            )}
          </Styled.SidebarBodyInner>
        ) : null}
      </Styled.SidebarBody>
      <Styled.SidebarFooter>
        {isSidebarOpen && !isSidebarAnimating ? (
          <Styled.SidebarFooterInner
            initial={isFirstRender ? false : { opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <SidebarFooterLeft />
            <Styled.SidebarFooterRight>
              {constants.IS_TOUCH_SCREEN && (
                <Styled.SidebarFooterRightButton
                  tabIndex={!isShowingNewListPopover ? 0 : undefined}
                  onClick={() =>
                    setIsInReorderingSidebarMode(!isInReorderingSidebarMode)
                  }
                >
                  <Icons.GripIcon />
                </Styled.SidebarFooterRightButton>
              )}
              <Styled.SidebarFooterRightButton
                tabIndex={!isShowingNewListPopover ? 0 : undefined}
              >
                <Icons.SettingsIcon />
              </Styled.SidebarFooterRightButton>
            </Styled.SidebarFooterRight>
          </Styled.SidebarFooterInner>
        ) : null}
      </Styled.SidebarFooter>
      <Styled.MainBody>
        <MainContent />
      </Styled.MainBody>
      <Styled.MainFooter>MainFooter</Styled.MainFooter>
    </Styled.App>
  );
};
