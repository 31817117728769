import { Project } from "../../../types";

export const defaultProjectsById: Record<string, Project> = {
  DEFAULT_PROJECT_VACATION: {
    id: "DEFAULT_PROJECT_VACATION",
    name: "Vacation in Rome",
    areaId: "DEFAULT_AREA_FAMILY",
  },
  DEFAULT_PROJECT_PURCHASE: {
    id: "DEFAULT_PROJECT_PURCHASE",
    name: "Buy a New Car",
    areaId: "DEFAULT_AREA_FAMILY",
  },
  DEFAULT_PROJECT_PREPARE: {
    id: "DEFAULT_PROJECT_PREPARE",
    name: "Prepare Presentation",
    areaId: "DEFAULT_AREA_WORK",
  },
  DEFAULT_PROJECT_ATTEND: {
    id: "DEFAULT_PROJECT_ATTEND",
    name: "Attend Conference",
    areaId: "DEFAULT_AREA_WORK",
  },
  DEFAULT_PROJECT_LEARN: {
    id: "DEFAULT_PROJECT_LEARN",
    name: "Learn Basic Italian",
    areaId: "DEFAULT_AREA_HOBBIES",
  },
  DEFAULT_PROJECT_RUN: {
    id: "DEFAULT_PROJECT_RUN",
    name: "Run a Marathon",
    areaId: "DEFAULT_AREA_HOBBIES",
  },
  DEFAULT_PROJECT_WITHOUT_AREA: {
    id: "DEFAULT_PROJECT_WITHOUT_AREA",
    name: "Get started with Things",
    areaId: null,
  },
};
