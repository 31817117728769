import React from "react";

import { useAppStore } from "../store";
import { MainContentDynamicHeader } from "./MainContentDynamicHeader";
import { MainContentStaticHeader } from "./MainContentStaticHeader";

export const MainContentHeader: React.FC = () => {
  const selectedSidebarItem = useAppStore((state) => state.selectedSidebarItem);

  if (selectedSidebarItem.type === "section") {
    return (
      <MainContentStaticHeader selectedSidebarItemId={selectedSidebarItem.id} />
    );
  }

  return (
    <MainContentDynamicHeader
      selectedSidebarItemType={selectedSidebarItem.type}
      selectedSidebarItemId={selectedSidebarItem.id}
    />
  );
};
