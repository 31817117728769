import { SectionId } from "../types";
import { IconComponent } from "./types";

import { InboxIcon } from "./InboxIcon";
import { TodayIcon } from "./TodayIcon";
import { UpcomingIcon } from "./UpcomingIcon";
import { AnytimeIcon } from "./AnytimeIcon";
import { LogbookIcon } from "./LogbookIcon";
import { SomedayIcon } from "./SomedayIcon";
import { TrashIcon } from "./TrashIcon";

export const iconsBySectionId: Record<SectionId, IconComponent> = {
  inbox: InboxIcon,
  today: TodayIcon,
  upcoming: UpcomingIcon,
  anytime: AnytimeIcon,
  logbook: LogbookIcon,
  someday: SomedayIcon,
  trash: TrashIcon,
};
