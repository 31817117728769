import React from "react";

import * as Styled from "../Styled";

type Props = {
  defaultTitle: string;
  placeholder: string;
  onSaveTitle: (newTitle: string) => void;
};

export const StaticSidebarItemRenameInput: React.FC<Props> = ({
  defaultTitle,
  placeholder,
  onSaveTitle,
}) => {
  const [titleDraft, setTitleDraft] = React.useState(defaultTitle);

  return (
    <Styled.SidebarItemRenameInput
      value={titleDraft}
      type="text"
      onChange={(e) => {
        const { value } = e.target;
        setTitleDraft(value);
      }}
      placeholder={placeholder}
      autoFocus
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === "Escape") {
          onSaveTitle(titleDraft);
        }
      }}
      onBlur={() => {
        onSaveTitle(titleDraft);
      }}
    />
  );
};
