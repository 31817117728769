import React from "react";

import * as Styled from "../Styled";
import { DynamicSidebarItem } from "../types";

type Props = React.PropsWithChildren<{
  onDragStart: () => void;
  onDragEnd: () => void;
  showInitialDynamicSidebarReorderItemAnimation: boolean;
  dynamicSidebarItem: DynamicSidebarItem;
}>;

export const DynamicSidebarReorderItem: React.FC<Props> = ({
  onDragStart,
  onDragEnd,
  showInitialDynamicSidebarReorderItemAnimation,
  dynamicSidebarItem,
  children,
}) => {
  const El =
    dynamicSidebarItem.type === "area"
      ? Styled.DynamicSidebarReorderItemLeader
      : Styled.DynamicSidebarReorderItem;

  return (
    <El
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      initial={
        showInitialDynamicSidebarReorderItemAnimation ? { opacity: 0.2 } : false
      }
      drag="y"
      animate={{ opacity: 1 }}
      value={dynamicSidebarItem}
    >
      {children}
    </El>
  );
};
