import * as constants from "../../constants";

import { StateCreator } from "../state";

import { SidebarResizingSlice } from "./sidebarResizingSlice/types";

export const createSidebarResizingSlice: StateCreator<SidebarResizingSlice> = (
  set
) => ({
  isDraggingSidebar: false,
  setIsDraggingSidebar: (is) => {
    set(() => ({ isDraggingSidebar: is }));
  },

  isSidebarOpen: true,
  isSidebarAnimating: false,
  openSidebar: () => {
    set(() => ({ isSidebarOpen: true, isSidebarAnimating: true }));
    setTimeout(() => {
      set(() => ({ isSidebarAnimating: false }));
    }, constants.SIDEBAR_ANIMATION_DURATION);
  },
  closeSidebar: () => {
    set(() => ({ isSidebarOpen: false, isSidebarAnimating: true }));
    setTimeout(() => {
      set(() => ({ isSidebarAnimating: false }));
    }, constants.SIDEBAR_ANIMATION_DURATION);
  },

  sidebarHandleX: constants.INITIAL_SIDEBAR_HANDLE_X,
  setSidebarHandleX: (to) => set(() => ({ sidebarHandleX: to })),
});
