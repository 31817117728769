import React from "react";

import * as Styled from "./Styled";
import { iconsBySectionId } from "../icons";

import { useAppStore } from "../store";
import { Section } from "../types";
import { sectionNamesById } from "../constants";

type Props = {
  selectedSidebarItemId: string;
};

export const MainContentStaticHeader: React.FC<Props> = ({
  selectedSidebarItemId,
}) => {
  const sectionsById = useAppStore((state) => state.sectionsById);

  const selectedSection: Section | undefined =
    sectionsById[selectedSidebarItemId];

  if (!selectedSection) {
    return null;
  }

  const title = sectionNamesById[selectedSection.id];
  const Icon = iconsBySectionId[selectedSection.id];

  return (
    <Styled.MainContentHeaderContainer>
      <Styled.MainContentHeader>
        <Icon />
        <Styled.MainContentTitle>{title}</Styled.MainContentTitle>
      </Styled.MainContentHeader>
    </Styled.MainContentHeaderContainer>
  );
};
