import { v4 as uuidV4 } from "uuid";

import { Project, ItemForContextMenu } from "../../types";
import { StateCreator } from "../state";

import { defaultProjectsById } from "./projectsSlice/defaults";
import { ProjectsSlice } from "./projectsSlice/types";
import { createAreasSlice } from "./areasSlice";

export const createProjectsSlice: StateCreator<ProjectsSlice> = (set) => ({
  renameProject: (id, name) => {
    set(({ projectsById }) => {
      const nextProjectsById = { ...projectsById };
      nextProjectsById[id] = {
        ...nextProjectsById[id],
        name,
      };
      return { projectsById: nextProjectsById };
    });
  },
  addProject: () => {
    const newProject: Project = {
      id: uuidV4(),
      name: "",
      areaId: null,
    };
    const item: ItemForContextMenu = {
      id: newProject.id,
      type: "project",
    };
    set(({ projectsById, projectIdsWithoutArea }) => {
      const nextProjectsById = { ...projectsById };
      nextProjectsById[newProject.id] = newProject;
      const nextProjectIdsWithoutArea = [
        newProject.id,
        ...projectIdsWithoutArea,
      ];
      return {
        projectsById: nextProjectsById,
        projectIdsWithoutArea: nextProjectIdsWithoutArea,
        sidebarItemBeingRenamed: item,
      };
    });
    return newProject.id;
  },
  removeProject: (projectId) => {
    const { removeProjectFromArea } = createAreasSlice(set);
    set(({ projectsById, projectIdsWithoutArea }) => {
      const project = projectsById[projectId];
      const areaId = project.areaId;
      const nextProjectIdsWithoutArea = projectIdsWithoutArea.filter(
        (x) => x !== projectId
      );
      if (areaId) {
        removeProjectFromArea(areaId, projectId);
      }

      const nextProjectsById = { ...projectsById };
      delete nextProjectsById[projectId];

      return {
        projectsById: nextProjectsById,
        projectIdsWithoutArea: nextProjectIdsWithoutArea,
      };
    });
  },
  projectIdsWithoutArea: ["DEFAULT_PROJECT_WITHOUT_AREA"],
  projectsById: defaultProjectsById,
});
