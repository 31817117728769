import React from "react";

import * as Styled from "./Styled";
import { ProjectIcon, AreaIcon, MoreOptionsIcon } from "../icons";

import { MainContentRenameTitleInput } from "./MainContentRenameTitleInput";

import { useAppStore } from "../store";
import { Project, Area, DynamicSidebarItem } from "../types";

type Props = {
  selectedSidebarItemId: string;
  selectedSidebarItemType: "project" | "area";
};

export const MainContentDynamicHeader: React.FC<Props> = ({
  selectedSidebarItemId,
  selectedSidebarItemType,
}) => {
  const itemBeingRenamed = useAppStore((state) => state.itemBeingRenamed);
  const zoneForItemBeingRenamed = useAppStore(
    (state) => state.zoneForItemBeingRenamed
  );
  const setItemBeingRenamed = useAppStore((state) => state.setItemBeingRenamed);

  const areasById = useAppStore((state) => state.areasById);
  const projectsById = useAppStore((state) => state.projectsById);

  const renameArea = useAppStore((state) => state.renameArea);
  const renameProject = useAppStore((state) => state.renameProject);

  const openContextMenu = useAppStore((state) => state.openContextMenu);

  const elForContextMenu = React.useRef<HTMLDivElement>(null);
  const itemForContextMenu = React.useMemo(
    () => ({
      type: selectedSidebarItemType,
      id: selectedSidebarItemId,
    }),
    [selectedSidebarItemType, selectedSidebarItemId]
  );

  const rename = React.useCallback(
    (item: DynamicSidebarItem, newTitle: string) => {
      if (item.type === "area") {
        renameArea(item.id, newTitle);
      }
      if (item.type === "project") {
        renameProject(item.id, newTitle);
      }
    },
    [renameArea, renameProject]
  );

  let selectedSidebarItem: Project | Area | undefined;
  let icon: React.ReactNode;
  let placeholder = "";
  if (selectedSidebarItemType === "area") {
    selectedSidebarItem = areasById[selectedSidebarItemId];
    icon = <AreaIcon />;
    placeholder = "New Area";
  }
  if (selectedSidebarItemType === "project") {
    selectedSidebarItem = projectsById[selectedSidebarItemId];
    icon = <ProjectIcon />;
    placeholder = "New Project";
  }

  if (!selectedSidebarItem) {
    return null;
  }

  const displayTitle = selectedSidebarItem.name || placeholder;

  const isRenaming = Boolean(
    itemBeingRenamed && zoneForItemBeingRenamed === "main"
  );

  return (
    <Styled.MainContentHeaderContainer>
      <Styled.MainContentHeader>
        {icon}
        <Styled.MainContentTitle
          isRenaming={isRenaming}
          onClick={() =>
            setItemBeingRenamed({
              zone: "main",
              item: itemForContextMenu,
            })
          }
        >
          {isRenaming ? (
            <MainContentRenameTitleInput
              defaultTitle={selectedSidebarItem.name}
              placeholder={placeholder}
              onSaveTitle={(newTitle) => {
                rename(itemForContextMenu, newTitle);
                setItemBeingRenamed({ item: null, zone: null });
              }}
            />
          ) : (
            displayTitle
          )}
        </Styled.MainContentTitle>
        {!isRenaming ? (
          <div
            ref={elForContextMenu}
            onClick={() => {
              openContextMenu({
                zoneForContextMenu: "main",
                elForContextMenu,
                itemForContextMenu,
              });
            }}
          >
            <MoreOptionsIcon />
          </div>
        ) : null}
      </Styled.MainContentHeader>
      <Styled.MainContentNotes>Notes</Styled.MainContentNotes>
    </Styled.MainContentHeaderContainer>
  );
};
