import React from "react";

import * as Styled from "../Styled";
import * as Icons from "../icons";
import { useAppStore } from "../store";
import { PopoverBackgroundOverlay } from "./PopoverBackgroundOverlay";

export const PopupContextMenu: React.FC = () => {
  const zoneForContextMenu = useAppStore((state) => state.zoneForContextMenu);
  const elForContextMenu = useAppStore((state) => state.elForContextMenu);
  const itemForContextMenu = useAppStore((state) => state.itemForContextMenu);
  const closeContextMenu = useAppStore((state) => state.closeContextMenu);

  const removeArea = useAppStore((state) => state.removeArea);
  const removeProject = useAppStore((state) => state.removeProject);

  const setItemBeingRenamed = useAppStore((state) => state.setItemBeingRenamed);

  const isShowing = Boolean(elForContextMenu && itemForContextMenu);

  let transformOrigin: string | undefined;
  let translate: string | undefined;
  let leftPx: number = 0;
  let topPx: number = 0;

  if (elForContextMenu && elForContextMenu.current) {
    if (zoneForContextMenu === "sidebar") {
      transformOrigin = "center left";
      leftPx =
        elForContextMenu.current.offsetLeft +
        elForContextMenu.current.offsetWidth +
        10;
      topPx = elForContextMenu.current.offsetTop;
    } else if (zoneForContextMenu === "main") {
      transformOrigin = "top center";
      translate = "-50%";
      leftPx =
        elForContextMenu.current.offsetLeft +
        elForContextMenu.current.offsetWidth -
        10;
      topPx = elForContextMenu.current.offsetTop + 40;
    }
  }

  const [hoveredElement, setHoveredElement] = React.useState<string | null>(
    null
  );

  const menuItemsJsx: JSX.Element[] = React.useMemo(() => {
    const jsx = [];
    if (itemForContextMenu) {
      if (
        itemForContextMenu.type === "area" ||
        itemForContextMenu.type === "project"
      ) {
        // TODO: Need a confirm dialog for deleting non-empty entities
        const onDeleteClick = () => {
          if (itemForContextMenu.type === "area") {
            removeArea(itemForContextMenu.id);
          } else {
            removeProject(itemForContextMenu.id);
          }
          setHoveredElement(null);
          closeContextMenu();
        };

        const onRenameClick = () => {
          setItemBeingRenamed({
            item: itemForContextMenu,
            zone: zoneForContextMenu,
          });
          setHoveredElement(null);
          closeContextMenu();
        };

        jsx.push(
          <Styled.PopupContextMenuItem
            key="RENAME"
            onMouseOver={() => setHoveredElement("RENAME")}
            onMouseOut={() => setHoveredElement(null)}
            onClick={onRenameClick}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onRenameClick();
              }
            }}
            tabIndex={0}
          >
            <Styled.PopupContextMenuItemIcon>
              <Icons.RenameIcon isHovered={hoveredElement === "RENAME"} />
            </Styled.PopupContextMenuItemIcon>
            <Styled.PopupContextMenuItemTitle>
              Rename
            </Styled.PopupContextMenuItemTitle>
          </Styled.PopupContextMenuItem>
        );
        jsx.push(
          <Styled.PopupContextMenuItem
            key="DELETE"
            onMouseOver={() => setHoveredElement("DELETE")}
            onMouseOut={() => setHoveredElement(null)}
            onClick={onDeleteClick}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onDeleteClick();
              }
            }}
            tabIndex={0}
          >
            <Styled.PopupContextMenuItemIcon>
              <Icons.DeleteIcon isHovered={hoveredElement === "DELETE"} />
            </Styled.PopupContextMenuItemIcon>
            <Styled.PopupContextMenuItemTitle>
              Delete
            </Styled.PopupContextMenuItemTitle>
          </Styled.PopupContextMenuItem>
        );
      } else {
        jsx.push(
          <Styled.EmptyPopupContextMenuItem key="EMPTY">
            No Options Available
          </Styled.EmptyPopupContextMenuItem>
        );
      }
    }
    return jsx;
  }, [
    zoneForContextMenu,
    itemForContextMenu,
    hoveredElement,
    closeContextMenu,
    removeArea,
    removeProject,
    setItemBeingRenamed,
  ]);

  return (
    <>
      <PopoverBackgroundOverlay
        isShowing={isShowing}
        onClick={() => {
          closeContextMenu();
        }}
      />
      <Styled.PopupContextMenu
        style={{
          left: `${leftPx}px`,
          top: `${topPx}px`,
          translate,
          transformOrigin,
        }}
        hide={!isShowing}
      >
        {menuItemsJsx}
      </Styled.PopupContextMenu>
    </>
  );
};
