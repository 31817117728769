import { StateCreator } from "../state";

import { SidebarSectionsSlice } from "./sidebarSectionsSlice/types";

export const createSidebarSectionsSlice: StateCreator<SidebarSectionsSlice> = (
  set
) => ({
  sectionIds: [
    "inbox",
    "today",
    "upcoming",
    "anytime",
    "someday",
    "logbook",
    "trash",
  ],

  sectionsById: {
    inbox: {
      id: "inbox",
      count: 2,
    },
    today: {
      id: "today",
      count: 3,
    },
    upcoming: { id: "upcoming" },
    anytime: { id: "anytime" },
    someday: { id: "someday" },
    logbook: { id: "logbook" },
    trash: { id: "trash" },
  },
});
