import * as constants from "../constants";

export const getDragConstraintsForSidebarResizerHandle = () => {
  const leftDragConstraint = -(
    constants.INITIAL_SIDEBAR_HANDLE_X - constants.MIN_SIDEBAR_WIDTH
  );
  const rightDragConstraint =
    window.innerWidth - constants.TOTAL_SIDEBAR_HANDLE_WIDTH_FROM_LEFT;
  const dragConstraints = {
    left: leftDragConstraint,
    right: rightDragConstraint,
  };
  return dragConstraints;
};
