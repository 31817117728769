import React from "react";

import * as Styled from "../Styled";
import * as Icons from "../icons";
import { useAppStore } from "../store";
import { PopoverBackgroundOverlay } from "./PopoverBackgroundOverlay";
import { NewListPopup } from "./NewListPopup";

export const SidebarFooterLeft: React.FC = () => {
  const isShowingNewListPopover = useAppStore(
    (state) => state.isShowingNewListPopover
  );
  const elForContextMenu = useAppStore((state) => state.elForContextMenu);
  const isShowingAPopover = Boolean(
    isShowingNewListPopover || elForContextMenu
  );
  const showNewListPopover = useAppStore((state) => state.showNewListPopover);
  const hideNewListPopover = useAppStore((state) => state.hideNewListPopover);

  return (
    <Styled.SidebarFooterLeft>
      <Styled.NewListButton
        tabIndex={!isShowingAPopover ? 0 : undefined}
        onClick={showNewListPopover}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            showNewListPopover();
          }
        }}
      >
        <Icons.PlusIcon /> New List
      </Styled.NewListButton>
      <PopoverBackgroundOverlay
        isShowing={isShowingNewListPopover}
        onClick={hideNewListPopover}
      />
      <NewListPopup />
    </Styled.SidebarFooterLeft>
  );
};
