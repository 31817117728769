import { v4 as uuidV4 } from "uuid";

import { Area, ItemForContextMenu } from "../../types";

import { StateCreator } from "../state";

import { defaultAreaIds, defaultAreasById } from "./areasSlice/defaults";
import { AreasSlice } from "./areasSlice/types";

export const createAreasSlice: StateCreator<AreasSlice> = (set) => ({
  areaIds: defaultAreaIds,
  areasById: defaultAreasById,
  addArea: () => {
    const newArea: Area = {
      id: uuidV4(),
      name: "",
      projectIds: [],
    };
    const item: ItemForContextMenu = {
      id: newArea.id,
      type: "area",
    };
    set(({ areaIds, areasById }) => {
      const nextAreasById = { ...areasById };
      nextAreasById[newArea.id] = newArea;
      const nextAreaIds = [newArea.id, ...areaIds];
      return {
        areaIds: nextAreaIds,
        areasById: nextAreasById,
        sidebarItemBeingRenamed: item,
      };
    });
    return newArea.id;
  },
  removeArea: (id) => {
    set(({ areaIds, areasById }) => {
      const nextAreasById = { ...areasById };
      delete nextAreasById[id];
      const nextAreaIds = areaIds.filter((x) => x !== id);
      return { areaIds: nextAreaIds, areasById: nextAreasById };
    });
  },
  renameArea: (id, name) => {
    set(({ areasById }) => {
      const nextAreasById = { ...areasById };
      nextAreasById[id] = {
        ...nextAreasById[id],
        name,
      };
      return { areasById: nextAreasById };
    });
  },
  removeProjectFromArea: (areaId, projectId) => {
    set(({ areasById }) => {
      const nextAreasById = { ...areasById };
      const area = areasById[areaId];
      const nextArea = { ...area };
      nextArea.projectIds = nextArea.projectIds.filter((x) => x !== projectId);
      nextAreasById[areaId] = nextArea;
      return {
        areasById: nextAreasById,
      };
    });
  },
});
