import React from "react";

import * as Styled from "../Styled";

type Props = {
  isShowing: boolean;
  onClick: () => void;
};

export const PopoverBackgroundOverlay: React.FC<Props> = ({
  isShowing,
  onClick,
}) => {
  return (
    <Styled.PopoverBackgroundOverlay
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClick();
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        onClick();
      }}
      hidden={!isShowing}
    />
  );
};
