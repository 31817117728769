import React from "react";
import { MotionValue } from "framer-motion";

import * as constants from "../constants";
import * as Styled from "../Styled";
import { useAppStore } from "../store";
import { getDragConstraintsForSidebarResizerHandle } from "../utils/getDragConstraintsForSidebarResizerHandle";

const motionValueForSidebarResizerHandle = new MotionValue<number>();

export const SidebarResizer: React.FC = () => {
  // Verify there is only one instance of this singleton component.
  // > This component must be a singleton because it uses an external variable
  //   to store its drag state (motionValueForSidebarResizerHandle)
  //   and persist it, even when unmounted.
  React.useLayoutEffect(() => {
    const totalSidebarResizers =
      document.querySelectorAll("#SidebarResizer").length;
    if (totalSidebarResizers > 1) {
      throw new Error(
        "SidebarResizer is a singleton component. You cannot have more than one instance on a page."
      );
    }
  }, []);

  // Handling the MotionValue for the sidebar handle
  React.useEffect(() => {
    // On window resize, adjust the sidebar handle if it is off screen
    const onResize = () => {
      const maxRight = getDragConstraintsForSidebarResizerHandle().right;
      if (motionValueForSidebarResizerHandle.get() > maxRight) {
        motionValueForSidebarResizerHandle.set(maxRight);
      }
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  // Handling storing the actual position of the sidebar handle
  const sidebarHandleX = useAppStore((state) => state.sidebarHandleX);
  const setSidebarHandleX = useAppStore((state) => state.setSidebarHandleX);
  const sidebarHandleTranslateX =
    sidebarHandleX - constants.INITIAL_SIDEBAR_HANDLE_X;
  React.useEffect(() => {
    return motionValueForSidebarResizerHandle.on("change", (value) => {
      const dragConstraints = getDragConstraintsForSidebarResizerHandle();
      if (value < dragConstraints.left) {
        setSidebarHandleX(
          constants.INITIAL_SIDEBAR_HANDLE_X + dragConstraints.left
        );
      } else if (value > dragConstraints.right) {
        setSidebarHandleX(
          constants.INITIAL_SIDEBAR_HANDLE_X + dragConstraints.right
        );
      } else {
        setSidebarHandleX(constants.INITIAL_SIDEBAR_HANDLE_X + value);
      }
    });
  }, [setSidebarHandleX]);

  // Handling the effect of showing the sidebar handle
  const isSidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSidebarAnimating = useAppStore((state) => state.isSidebarAnimating);
  const [isMouseOverSidebarResizerHandle, setIsMouseOverSidebarResizerHandle] =
    React.useState(false);
  const [
    isMouseOverSidebarResizerAppearZone,
    setIsMouseOverSidebarResizerAppearZone,
  ] = React.useState(false);
  let shouldShowHandle = false;
  if (constants.IS_TOUCH_SCREEN) {
    shouldShowHandle = !isSidebarAnimating;
  } else {
    shouldShowHandle =
      isMouseOverSidebarResizerAppearZone || isMouseOverSidebarResizerHandle;
  }
  const opacityForHandle = shouldShowHandle ? 1 : 0;

  // Handling dragging and closing the sidebar
  const closeSidebar = useAppStore((state) => state.closeSidebar);
  const setIsDraggingSidebar = useAppStore(
    (state) => state.setIsDraggingSidebar
  );
  const isThisClickADragRef = React.useRef<boolean>(false);
  const onMouseDown = () => {
    isThisClickADragRef.current = false;
  };
  const onDragStart = () => {
    setIsDraggingSidebar(true);
    isThisClickADragRef.current = true;
  };
  const onDragEnd = () => {
    setIsDraggingSidebar(false);
  };
  const onClick = () => {
    if (!isThisClickADragRef.current) {
      setIsMouseOverSidebarResizerAppearZone(false);
      setIsMouseOverSidebarResizerHandle(false);
      closeSidebar();
    }
  };

  if (!isSidebarOpen) {
    return null;
  }
  return (
    <div id="SidebarResizer">
      <Styled.SidebarResizerAppearZone
        style={{ left: `${sidebarHandleX}px` }}
        onMouseOver={() => setIsMouseOverSidebarResizerAppearZone(true)}
        onMouseOut={() => setIsMouseOverSidebarResizerAppearZone(false)}
      />
      <Styled.SidebarResizerHandle
        _dragX={motionValueForSidebarResizerHandle}
        drag="x"
        dragMomentum={false}
        dragElastic={false}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        dragConstraints={getDragConstraintsForSidebarResizerHandle()}
        onMouseOver={() => setIsMouseOverSidebarResizerHandle(true)}
        onMouseOut={() => setIsMouseOverSidebarResizerHandle(false)}
        onMouseDown={onMouseDown}
        onClick={onClick}
        style={{
          translateX: sidebarHandleTranslateX,
          opacity: opacityForHandle,
        }}
      />
    </div>
  );
};
