import React from "react";
import isEqual from "lodash/isEqual";

import * as constants from "../constants";
import * as Styled from "../Styled";
import * as Icons from "../icons";
import { useAppStore } from "../store";
import { ItemForContextMenu } from "../types";
import { StaticSidebarItemRenameInput } from "../components/StaticSidebarItemRenameInput";

type Props = {
  Icon: Icons.IconComponent;
  id: string;
  type: "section" | "area" | "project";
  title: string;
  count?: number;
};

export const StaticSidebarItem: React.FC<Props> = ({
  Icon,
  id,
  type,
  title,
  count,
}) => {
  const elRef = React.useRef<HTMLDivElement>(null);

  const openContextMenu = useAppStore((state) => state.openContextMenu);
  const isInReorderingSidebarMode = useAppStore(
    (state) => state.isInReorderingSidebarMode
  );

  const isShowingNewListPopover = useAppStore(
    (state) => state.isShowingNewListPopover
  );
  const elForContextMenu = useAppStore((state) => state.elForContextMenu);
  const isShowingAPopover = Boolean(
    isShowingNewListPopover || elForContextMenu
  );
  const selectedSidebarItem = useAppStore((state) => state.selectedSidebarItem);
  const selectSidebarItem = useAppStore((state) => state.selectSidebarItem);
  const isSelected = selectedSidebarItem.id === id;

  const itemBeingRenamed = useAppStore((state) => state.itemBeingRenamed);
  const zoneForItemBeingRenamed = useAppStore(
    (state) => state.zoneForItemBeingRenamed
  );
  const setItemBeingRenamed = useAppStore((state) => state.setItemBeingRenamed);
  const renameProject = useAppStore((state) => state.renameProject);
  const renameArea = useAppStore((state) => state.renameArea);

  const onClick = () => selectSidebarItem({ id, type });

  const itemForContextMenu = React.useMemo(
    (): ItemForContextMenu => ({ type, id }),
    [type, id]
  );

  let displayTitle = title;
  let placeholder = "";
  if (type === "project") {
    placeholder = "New Project";
    if (title === "") {
      displayTitle = placeholder;
    }
  }
  if (type === "area") {
    placeholder = "New Area";
    if (title === "") {
      displayTitle = placeholder;
    }
  }

  return (
    <Styled.StaticSidebarItem
      ref={elRef}
      isSelected={isSelected}
      tabIndex={!isShowingAPopover ? 0 : undefined}
      onMouseDown={onClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClick();
        }
      }}
      onDoubleClick={
        type === "section"
          ? undefined
          : () => {
              setItemBeingRenamed({
                item: itemForContextMenu,
                zone: "sidebar",
              });
            }
      }
      onContextMenu={(e) => {
        e.preventDefault();
        openContextMenu({
          zoneForContextMenu: "sidebar",
          elForContextMenu: elRef,
          itemForContextMenu: itemForContextMenu,
        });
      }}
    >
      <Styled.StaticSidebarItemName>
        {isInReorderingSidebarMode &&
          type !== "section" &&
          constants.IS_TOUCH_SCREEN && <Icons.GripIcon />}
        <Icon />
        <Styled.StaticSidebarItemNameText>
          {zoneForItemBeingRenamed === "sidebar" &&
          isEqual(itemBeingRenamed, itemForContextMenu) ? (
            <StaticSidebarItemRenameInput
              defaultTitle={title}
              placeholder={placeholder}
              onSaveTitle={(newTitle) => {
                if (itemForContextMenu.type === "area") {
                  renameArea(itemForContextMenu.id, newTitle);
                } else {
                  renameProject(itemForContextMenu.id, newTitle);
                }
                setItemBeingRenamed({ item: null, zone: null });
              }}
            />
          ) : (
            displayTitle
          )}
        </Styled.StaticSidebarItemNameText>
      </Styled.StaticSidebarItemName>
      <Styled.StaticSidebarItemCount>{count}</Styled.StaticSidebarItemCount>
    </Styled.StaticSidebarItem>
  );
};
