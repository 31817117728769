import { StateCreator } from "../state";

import { SidebarSelectedSlice } from "./sidebarSelectedSlice/types";

export const createSidebarSelectedSlice: StateCreator<SidebarSelectedSlice> = (
  set
) => ({
  selectedSidebarItem: { type: "section", id: "today" },
  selectSidebarItem: (to) => {
    set(() => ({ itemBeingRenamed: null, selectedSidebarItem: to }));
  },
});
