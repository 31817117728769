import { create } from "zustand";

import { State } from "./store/state";

import { createAreasSlice } from "./store/slices/areasSlice";
import { createContextMenuSlice } from "./store/slices/contextMenuSlice";
import { createProjectsSlice } from "./store/slices/projectsSlice";
import { createSidebarNewListPopover } from "./store/slices/sidebarNewListPopoverSlice";
import { createRenamingSlice } from "./store/slices/renamingSlice";
import { createSidebarReorderingSlice } from "./store/slices/sidebarReorderingSlice";
import { createSidebarResizingSlice } from "./store/slices/sidebarResizingSlice";
import { createSidebarSectionsSlice } from "./store/slices/sidebarSectionsSlice";
import { createSidebarSelectedSlice } from "./store/slices/sidebarSelectedSlice";

export const useAppStore = create<State>((set) => ({
  ...createAreasSlice(set),
  ...createContextMenuSlice(set),
  ...createProjectsSlice(set),
  ...createSidebarNewListPopover(set),
  ...createRenamingSlice(set),
  ...createSidebarReorderingSlice(set),
  ...createSidebarResizingSlice(set),
  ...createSidebarSectionsSlice(set),
  ...createSidebarSelectedSlice(set),
}));
